import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../services/UserContext";
import {
  fullAccess,
  supervisor,
  admin,
  testing,
  level1,
} from "../config/levelGroups";

function AdminPanel() {
  const { user } = useUser();

  return (
    <div>
      <h2>Admin Panel</h2>
      <nav>
        <ul>
          {/* ------------------------------------------------------------------------ */}
          {user && fullAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/BlackList">Lista Negra</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && fullAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/UserInformation">Informacion de Usuario</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && fullAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/enrollmentVerify">
                  Verificar correcto enrolamiento Nuovopay
                </Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && admin.includes(user.email) && (
            <>
              <li>
                <Link to="/UnrollmentManualy">
                  Eliminar Nuovopay (Creditos Pagos)
                </Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && fullAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/SendEmailApproved">
                  Enviar Correo de Aprobacion (Solo Enrolamiento: True)
                </Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && fullAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/generateClabe">Generar Clabe STP</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && admin.includes(user.email) && (
            <>
              <li>
                <Link to="/NuovoPay">Desbloqueo NuovoPay</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && admin.includes(user.email) && (
            <>
              <li>
                <Link to="/RenovationExceptional">Creditos Excepcionales</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && fullAccess.includes(user.email) && (
            <>
              <li>
                <Link to="/nextCreditProjection">
                  Proyeccion proximo credito
                </Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
          {user && supervisor.includes(user.email) && (
            <>
              <li>
                <Link to="/reports">Reportes</Link>
              </li>
              <br />
            </>
          )}
          {/* ------------------------------------------------------------------------ */}
        </ul>
      </nav>
    </div>
  );
}

export default AdminPanel;
